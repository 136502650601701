import { AssignmentInd, Groups3 } from "@mui/icons-material";
import BrushIcon from "@mui/icons-material/Brush";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import EmailIcon from "@mui/icons-material/Email";
import EuroIcon from "@mui/icons-material/Euro";
import LanguageIcon from "@mui/icons-material/Language";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import People from "@mui/icons-material/People";
import PercentIcon from "@mui/icons-material/Percent";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SendAndArchiveTwoTone from "@mui/icons-material/SendAndArchiveTwoTone";
import { StateMachineProvider as OrigStateMachineProvider } from "little-state-machine";
import * as React from "react";
import { Admin, Authenticated, CustomRoutes, Resource, defaultTheme } from "react-admin";
import { BrowserRouter, Route } from "react-router-dom";
import BasketProvider from "./basket/basketProvider";
import { createGlobalStore } from "./globalState";
import { i18nProvider } from "./i18n";

import {
  EmailProductDetailTextCreate,
  EmailProductDetailTextEdit,
  EmailProductDetailTextList,
} from "./list/emailProducts";
import { EmailTemplatesEdit, EmailTemplatesList } from "./list/emailTemplates";
import { InternalUserEdit, InternalUserList, InternalUserShow } from "./list/internalUser";
import {
  InternalUserGroupCreate,
  InternalUserGroupEdit,
  InternalUserGroupList,
  InternalUserGroupShow,
} from "./list/internalUserGroup";
import { LocaleCreate, LocaleEdit, LocaleShow, LocalesList } from "./list/locales";
import { NewBundleCreate, NewBundleEdit, NewBundleList } from "./list/newBundle";
import { NewProductList, NewProductsEdit, NewProductsShow } from "./list/newProduct";
import { OrderEdit, OrderList } from "./list/order";
import Order from "./list/orders";
import { PriceEdit, PriceList } from "./list/price";
import { ProductTagsList } from "./list/productTags";
import { PromotionCodeCreate, PromotionCodeEdit, PromotionCodeList } from "./list/promotionCode";
import {
  ReferralCodeListMessage,
  ReferralCodeMessageCreate,
  ReferralCodeMessageEdit,
} from "./list/referralCodeMessage";
import ShopifyOrdersList from "./list/shopifyOrders";
import UsersList from "./list/users";
import { SidebarLayout } from "./menu/layout";
import SSOAuthProvider from "./providers/SSOAuthProvider";
import grapheneDataProvider from "./providers/grapheneDataProvider2";
import Basket from "./screens/basket";
import Checkout from "./screens/checkout";
import Dashboard from "./screens/dashboard";
import InvoiceArchiveList from "./screens/invoicesArchive";
import { SSOLogin, SSOLoginCallback } from "./screens/login";
import { OrderCharts } from "./screens/orderCharts";
import Settings from "./screens/settings";
import ShopifyOrderDetail from "./screens/shopifyOrderDetail";
import ThankYou from "./screens/thankYou";
import UserDetail from "./screens/userDetail";
const StateMachineProvider: React.FC<{ children: React.ReactNode }> = OrigStateMachineProvider;

createGlobalStore();

const theme = {
  ...defaultTheme,
};

const App = () => (
  <BrowserRouter>
    <StateMachineProvider>
      <BasketProvider>
        <Admin
          dashboard={Dashboard}
          layout={SidebarLayout}
          dataProvider={grapheneDataProvider}
          loginPage={SSOLogin}
          authProvider={SSOAuthProvider}
          i18nProvider={i18nProvider}
          disableTelemetry
          theme={theme}
        >
          <Resource
            name="orders"
            list={OrderList}
            edit={OrderEdit}
            icon={RequestQuoteIcon}
            options={{ label: "Orders" }}
          />
          <Resource
            name="shipping"
            list={Order.list}
            // edit={OrderEdit}
            icon={Order.icon}
            options={{ label: "Shipping" }}
          />

          <Resource
            name="v2Products"
            list={<NewProductList hasEdit={false} />}
            show={NewProductsShow}
            options={{ label: "Products v2" }}
            icon={BrushIcon}
            recordRepresentation={(record) => record.name}
            hasEdit={false}
          />
          <Resource
            name="v2Productpres"
            list={<NewProductList hasEdit={true} />}
            edit={NewProductsEdit}
            options={{ label: "Products Pre v2" }}
            icon={BrushIcon}
            recordRepresentation={(record) => record.name}
            hasShow={false}
          />
          <Resource
            name="productTags"
            list={ProductTagsList}
            icon={LocalOfferIcon}
            options={{ label: "Product Tags" }}
          />
          <Resource name="prices" list={PriceList} edit={PriceEdit} icon={EuroIcon} options={{ label: "Prices" }} />

          <Resource
            name="v2Bundles"
            list={<NewBundleList />}
            show={<NewBundleEdit hasSave={false} />}
            icon={LibraryBooksIcon}
            options={{ label: "Bundles v2" }}
            hasEdit={false}
          />
          <Resource
            name="v2Bundlepres"
            list={<NewBundleList showCreate={true} />}
            edit={<NewBundleEdit hasSave={true} />}
            create={NewBundleCreate}
            icon={LibraryBooksIcon}
            options={{ label: "Bundles Pre v2" }}
            hasShow={false}
          />
          <Resource
            name="promotionCode"
            list={PromotionCodeList}
            edit={PromotionCodeEdit}
            create={PromotionCodeCreate}
            icon={PercentIcon}
            options={{ label: "Promotion Code" }}
          />
          {/* <Resource
            name="referralCodeIndividual"
            list={ReferralCodeListIndividual}
            edit={ReferralCodeIndividualEdit}
            create={ReferralCodeIndividualCreate}
            icon={ConnectWithoutContactIcon}
            options={{ label: "Referral Code" }}
          /> */}
          <Resource
            name="referralCodeMessage"
            list={ReferralCodeListMessage}
            edit={ReferralCodeMessageEdit}
            create={ReferralCodeMessageCreate}
            icon={ConnectWithoutContactIcon}
            options={{ label: "Referral Message" }}
          />
          <Resource
            name="emailProductDetailText"
            list={EmailProductDetailTextList}
            edit={EmailProductDetailTextEdit}
            create={EmailProductDetailTextCreate}
            icon={EmailIcon}
            options={{ label: "Email Products" }}
          />
          <Resource
            name="emailTemplates"
            list={EmailTemplatesList}
            edit={EmailTemplatesEdit}
            recordRepresentation={(record) => `${record.emailType}`}
            icon={EmailIcon}
            options={{ label: "Email Templates" }}
            hasShow={false}
            hasCreate={false}
          />
          <CustomRoutes>
            <Route
              path="/settings"
              element={
                <Authenticated>
                  <Settings />
                </Authenticated>
              }
            />
            <Route
              path="/order_charts"
              element={
                <Authenticated>
                  <OrderCharts />
                </Authenticated>
              }
            />
            <Route
              path="/basket"
              element={
                <Authenticated>
                  <Basket />
                </Authenticated>
              }
            />
            {/* <Route path="/payment" element={<Payment />} /> */}
          </CustomRoutes>
          <Resource
            name="invoices-archive"
            list={InvoiceArchiveList}
            options={{ label: "Invoices Archive" }}
            icon={SendAndArchiveTwoTone}
          />
          <Resource name="users" list={UsersList} show={UserDetail} options={{ label: "Customers" }} icon={People} />
          <Resource
            name="shopifyOrders"
            list={ShopifyOrdersList}
            show={ShopifyOrderDetail}
            options={{ label: "Shopify Orders" }}
            icon={LocalMallIcon}
          />
          <Resource
            name="locales"
            list={LocalesList}
            show={LocaleShow}
            edit={LocaleEdit}
            create={LocaleCreate}
            options={{ label: "Countries" }}
            icon={LanguageIcon}
            recordRepresentation={(record) => record.name}
          />
          <Resource
            name="internalUserGroups"
            list={InternalUserGroupList}
            show={InternalUserGroupShow}
            edit={InternalUserGroupEdit}
            create={InternalUserGroupCreate}
            options={{ label: "Internal User Groups" }}
            icon={Groups3}
            recordRepresentation={(record) => record.name}
          />
          <Resource
            name="internalUsers"
            list={InternalUserList}
            show={InternalUserShow}
            edit={InternalUserEdit}
            options={{ label: "Internal Users" }}
            icon={AssignmentInd}
            recordRepresentation={(record) => record.name}
          />
          <CustomRoutes noLayout>
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout/:checkoutId/processing" element={<Checkout processing={true} />} />
            <Route path="/checkout/:cartId" element={<Checkout />} />
            <Route path="/checkout/:checkoutId/customer" element={<Checkout />} />
            <Route path="/checkout/:checkoutId/payment" element={<Checkout />} />
            {/* <Route path="/thank_you/:orderId" element={<ThankYou />} /> */}
            <Route path="/thank_you/:checkoutId" element={<ThankYou />} />
            <Route path="/thank_you/" element={<ThankYou />} />
            <Route path="/auth/getToken" element={<SSOLoginCallback />} />
          </CustomRoutes>
        </Admin>
      </BasketProvider>
    </StateMachineProvider>
  </BrowserRouter>
);
export default App;
